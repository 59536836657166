import { SVGProps } from 'react';
import COLORS from '@utils/colors';
import { SVG_SIZES } from '@utils/constants';

interface IconProps extends SVGProps<SVGSVGElement> {
  color?: keyof typeof COLORS; // Custom prop for changing path colors
  size?: keyof typeof SVG_SIZES;
  title?: string;
  titleId?: string;
}

const IconSlash = ({
  color = 'white',
  size = 'md',
  style = {},
  title,
  titleId,
  ...props
}: IconProps) => {
  const _style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],

    //Style override by prop
    ...style,
  };

  return (
    <svg
      aria-labelledby={titleId}
      fill="none"
      style={_style}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <path
        clipRule="evenodd"
        d="M4 12C4 10.154 4.635 8.458 5.688 7.103L16.897 18.312C15.542 19.366 13.846 20 12 20C7.589 20 4 16.411 4 12ZM20 12C20 13.846 19.365 15.542 18.312 16.897L7.103 5.688C8.458 4.634 10.154 4 12 4C16.411 4 20 7.589 20 12ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z"
        fill={COLORS[color]}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default IconSlash;
